<template>
  <SafeTeleport v-if="drawers[name]" to="#drawer-slot" :force="true">
    <slot v-bind="$attrs"></slot>
  </SafeTeleport>
</template>

<script setup lang="ts">
import SafeTeleport from '@/apps/slideshow/teleport/SafeTeleport.vue';
import { useDrawer } from '../useLayout';

const props = defineProps(['name']);

const { drawers, registerDrawer } = useDrawer();
registerDrawer(props.name);
</script>

<style scoped></style>
